import { Text } from '@/atoms';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { EditPromoCode, InputPromoCode } from '@/molecules';
import { getAuth } from '@/services/identity.service';
import { applyPromoCode, removePromoCode } from '@/services/promoCode.services';

const PromoCodeOrganism = ({
  cartDetails,
  getCartDetails,
  isPromoCodeApplied,
  promoCodeError,
  setCartDetails,
  setIsPromoCodeApplied,
  setPromoCodeError,
  setShowPromoCodeAppliedModal,
  setShowToast
}) => {
  const {
    userCartId,
    id: cartId,
    derivedValues: { promoCodeDiscountAmount }
  } = cartDetails;

  const processRemovePromoCode = async () => {
    const { status, message } = await removePromoCode({
      userCartId,
      cartId
    });
    if (status) {
      getCartDetails();
      setIsPromoCodeApplied(!isPromoCodeApplied);
    } else {
      setShowToast({ show: true, status, message });
    }
  };

  const processApplyPromoCode = async (promoCode) => {
    const auth = getAuth();
    const {
      status,
      entity: { derivedValues = {} } = {},
      message = []
    } = await applyPromoCode({ userCartId: auth.id, cartId, promoCode });

    if (status) {
      setCartDetails({ ...cartDetails, derivedValues });
      if (
        derivedValues.promoCodeValidationInfo?.isValid &&
        derivedValues.promoCode
      ) {
        setPromoCodeError('');
        setIsPromoCodeApplied(true);
        setShowPromoCodeAppliedModal(true);
      } else {
        setPromoCodeError(derivedValues.promoCodeValidationInfo?.message);
      }
    } else {
      setPromoCodeError(message);
    }
  };

  return (
    <div className='w-full'>
      <Text
        {...{ content: 'Promo Code', className: 'text-lg font-medium mb-4' }}
      />
      {promoCodeDiscountAmount ? (
        <EditPromoCode
          {...{
            promoCode: cartDetails.derivedValues?.promoCode?.code,
            promoCodeAmount: parseFormatPriceValueFromAPI(
              cartDetails.derivedValues?.promoCodeDiscountAmount
            ),
            removePromoCode: processRemovePromoCode
          }}
        />
      ) : (
        <InputPromoCode
          {...{
            ...cartDetails,
            isPromoCodeApplied,
            processApplyPromoCode,
            promoCodeError,
            setIsPromoCodeApplied,
            setPromoCodeError
          }}
        />
      )}
    </div>
  );
};

export default PromoCodeOrganism;
