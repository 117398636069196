import Image from 'next/image';

import { Button, CartItemCTA, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { LineItemMedia, RackRateOfUnOrderedCartItem } from '@/molecules';
import { getSelectedPartner } from '@/services/plannerCart.service';

const PricedCartItemOrganism = ({
  cartId,
  cartItemIdInViewEditMode,
  haflaMarginPercent,
  id,
  isCartEditable,
  itemMediaForCarousel,
  lastCartItem,
  onClickCloneCartItem,
  onDeleteCartItemImage,
  partnerId,
  product,
  quantity,
  resequenceCartItems,
  sellingPrice,
  setCartItemIdForUploadMediaModal,
  setCartItemIdInViewEditMode,
  showProductMedia,
  unitRackPrice,
  unitSellingPrice,
  updateCartItemMediaSortOrder,
  verifyAndSetItemInRequestToDeleteMode
}) => {
  const unlockCTA = cartItemIdInViewEditMode !== id;
  const { slug, name, productPartners } = product;

  return (
    <div className='w-full'>
      <div
        className={`flex gap-5 text-sm font-light py-3 px-4 border-b border-l border-r ${
          lastCartItem ? 'rounded-b-lg' : ''
        } border-platinum ${
          cartItemIdInViewEditMode === id
            ? 'bg-planner-cart-head'
            : 'bg-lightgray'
        }`}
      >
        <div className='flex-1 flex gap-3 self-center'>
          {resequenceCartItems ? (
            <div className='h-10 pt-2 -ml-2'>
              <Image
                {...{
                  src: `${staticMediaStoreBaseURL}/icons/dragable-icon.png`,
                  height: 30,
                  width: 30,
                  alt: 'drag-icon'
                }}
              />
            </div>
          ) : (
            <LineItemMedia
              {...{
                cartId,
                cartItemId: id,
                isCartEditable,
                itemMediaForCarousel,
                onDeleteCartItemImage,
                productName: name,
                setCartItemIdForUploadMediaModal,
                showProductMedia,
                updateCartItemMediaSortOrder
              }}
            />
          )}
          <a
            href={resequenceCartItems ? undefined : `/products/${slug}`}
            className='self-center'
          >
            <Text
              {...{
                content: name,
                className: 'self-center flex-1 flex'
              }}
            />
          </a>
        </div>

        <div className='w-40 self-center'>
          <RackRateOfUnOrderedCartItem {...{ product, unitRackPrice }} />
        </div>

        <Text {...{ content: quantity, className: 'w-20 self-center' }} />
        <Text
          {...{
            content: parseFormatPriceValueFromAPI(unitSellingPrice),
            className: 'w-24 self-center'
          }}
        />
        <Text
          {...{
            content: parseFormatPriceValueFromAPI(sellingPrice),
            className: 'w-30 self-center'
          }}
        />
        <Text
          {...{
            content: getSelectedPartner({ productPartners, partnerId }),
            className: 'w-40 self-center'
          }}
        />
        <Text
          {...{
            content: `${parseFormatPriceValueFromAPI(haflaMarginPercent)} %`,
            className: `w-20 self-center ${
              Number(parseFormatPriceValueFromAPI(haflaMarginPercent)) >= 0
                ? 'text-green'
                : 'text-red-500'
            }`
          }}
        />
        {!resequenceCartItems && (
          <div className='w-40 self-center flex gap-3'>
            <Button
              {...{
                children: 'View',
                width: 'w-20',
                className:
                  'bg-gradient-to-r h-8 from-light-orange via-dark-orange to-rose text-white py-2 rounded-lg text-sm font-medium self-center',
                iconGroupClassName: 'reduce-gap',
                iconHeight: 15,
                iconWidth: 15,
                iconPosition: 'Left',
                iconUrl: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
                onClick: () => unlockCTA && setCartItemIdInViewEditMode(id)
              }}
            />
            <CartItemCTA
              {...{
                type: 'cloneCartItem',
                className: 'self-center',
                onClick: () => onClickCloneCartItem({ cartItemId: id })
              }}
            />
            <CartItemCTA
              {...{
                type: 'delete',
                onClick: () =>
                  isCartEditable &&
                  unlockCTA &&
                  verifyAndSetItemInRequestToDeleteMode(id),
                className: `self-center ${
                  isCartEditable && unlockCTA
                    ? ''
                    : 'pointer-events-none opacity-40'
                }`
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PricedCartItemOrganism;
