import Image from 'next/image';

import { Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { LineItemMedia, RackRateOfOrderedCartItem } from '@/molecules';

const OrderedCartItemOrganism = ({
  cartItemIdInViewEditMode,
  haflaMarginPercent,
  id,
  isCartEditable,
  isReadOnly,
  itemMediaForCarousel,
  onDeleteCartItemImage,
  partner,
  product,
  quantity,
  rackDiscountPercentage,
  sellingPrice,
  setCartItemIdForUploadMediaModal,
  setCartItemIdInViewEditMode,
  showProductMedia,
  unitListedDiscount,
  unitListedPrice,
  unitRackPrice,
  unitSellingPrice,
  updateCartItemMediaSortOrder
}) => {
  const { name, type, slug } = product;
  const cartItemFields = [
    { content: quantity, className: 'w-20 self-center' },
    {
      className: 'w-24 self-center',
      content: parseFormatPriceValueFromAPI(unitSellingPrice)
    },
    {
      className: 'w-30 self-center',
      content: parseFormatPriceValueFromAPI(sellingPrice)
    },

    {
      className: 'w-40 self-center',
      content: partner?.legalName || ''
    },
    {
      className: `w-20 self-center ${
        Number(parseFormatPriceValueFromAPI(haflaMarginPercent)) >= 0
          ? 'text-green'
          : 'text-red-500'
      }`,
      content: `${parseFormatPriceValueFromAPI(haflaMarginPercent)} %`
    }
  ];
  return (
    <div className='w-full'>
      <div className='flex gap-5 text-sm font-light py-3 border-b border-platinum bg-lightgray px-4 rounded-t-lg'>
        <div className='flex-1 flex gap-3 self-center'>
          <LineItemMedia
            {...{
              cartItemId: id,
              isCartEditable,
              isReadOnly,
              itemMediaForCarousel,
              onDeleteCartItemImage,
              productName: name,
              setCartItemIdForUploadMediaModal,
              showProductMedia,
              updateCartItemMediaSortOrder
            }}
          />
          <a
            href={`/products/${slug}`}
            className='self-center'
          >
            <Text
              {...{
                content: name,
                className: 'self-center flex-1 flex'
              }}
            />
          </a>
        </div>
        <div className='w-40 self-center'>
          <RackRateOfOrderedCartItem
            {...{
              rackDiscountPercentage,
              type,
              unitListedDiscount,
              unitListedPrice,
              unitRackPrice
            }}
          />
        </div>
        {cartItemFields.map(({ content, className }, index) => (
          <Text
            key={index}
            {...{ content, className }}
          />
        ))}

        <div
          className={`w-40 self-center flex justify-center transform rotate-${
            cartItemIdInViewEditMode === id ? '180' : '0'
          }`}
        >
          <Image
            alt='down'
            height={24}
            onClick={() => setCartItemIdInViewEditMode(id)}
            src={`${staticMediaStoreBaseURL}/icons/down-orange.svg`}
            width={22}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderedCartItemOrganism;
